.faq-section {
  @include media-breakpoint-up(lg) {
    padding-bottom: 85px;
  }

  .section__title {
    padding: 0 10px;
  }

  .faq {
    @include media-breakpoint-up(md) {
      max-width: 1312px;
      margin: 70px auto 0;
    }

    .accordion {
      margin-bottom: 8px;
      border-radius: 4px;
      overflow: hidden;

      @include media-breakpoint-up(md) {
        &:last-child {
          margin-bottom: 32px;
        }
      }

      &__header {
        border-radius: 4px 4px 4px 4px;
        border: 1px solid $color-light-green;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        background-color: $color-white;
        @include trans(background-color);

        @include media-breakpoint-up(md) {
          padding: 18px 32px;
        }

        &:hover {
          background-color: lighten($color-light-green, 30%);
        }

        &.active {
          background-color: $color-light-green;
          border-radius: 4px 4px 0 0;

          .accordion__indicator {

            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }

      &__title {
        font-weight: $font-weight-bold;
        font-size: 1.6rem;
        line-height: normal;
        margin-right: 10px;
        margin-bottom: 0;
        text-align: left;

        @include media-breakpoint-up(md){
          font-size: 2.4rem;
        }
      }

      &__indicator {
        flex-shrink: 0;

        svg {
          width: 20px;
          @include trans(transform);

          path {
            fill: transparent;
          }
        }
      }

      &__body {
        border-radius: 0 0 4px 4px;
        border-right: 1px solid $color-light-green;
        border-bottom: 1px solid $color-light-green;
        border-left: 1px solid $color-light-green;
        max-height: 0;
        margin-top: -1px;
        position: relative;
        overflow: hidden;
        @include trans(max-height);
      }

      .dynamic-content {
        padding: 16px 16px 24px;
        color: $text-color;
        font-size: 1.6rem;
        line-height: 23px;
        text-align: left;

        @include media-breakpoint-up(md) {
          padding: 16px 32px 24px;
          font-size: 1.8rem;
          line-height: 28px;
        }

        p {
          padding: 0;
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 23px;

          @include media-breakpoint-up(md){
            font-size: 1.8rem;
            line-height: 28px;
          }
        }

        ul, ol {
          margin-left: 32px;
          padding: 0;
        }

        ul {
          list-style: disc;
        }

        a {
          color: $color-green;
          @include trans(color);

          &:hover {
            color: $color-dark-green;
          }
        }
      }
    }
  }

  #faq-toggler-button {
    max-width: 304px;
    margin: 36px auto 0;
  }

  #faq-toggler-button-show-more.hidden, #faq-toggler-button-show-less.hidden {
    display: none;
  }

  #faq-hidden {
    max-width: 2000px;
    margin: auto;
    max-height: 0;
    overflow: hidden;
    will-change: max-height;
    @include trans(max-height);
  }

  &--main {
    .faq {
      @include media-breakpoint-up(md) {
        margin: 75px auto 0;
      }

      .accordion {
        border-radius: 0;

        &__header {
          border-radius: 0;
          border: 0;
          padding: 14px 32px;
          background: linear-gradient(270deg, rgba(213,159,62,1) 0%, rgba(167,109,46,1) 31%, rgba(131,79,40,1) 67%, rgba(56,38,24,1) 100%);
          @include trans(background-color, box-shadow);

          @include media-breakpoint-up(md) {
            padding: 19px 32px;
          }

          &:hover {
            box-shadow: inset 0 0 0 1px $color-black;
          }

          &.active {
            box-shadow: inset 0 0 0 1px $color-black;
            border-radius: 0;
          }
        }

        &__title {
          color: $color-white;
        }

        &__body {
          border: none !important;
          box-shadow: inset 0 0 0 1px $color-black;
          margin-top: 0;
          background: linear-gradient(270deg, rgba(216,197,150,1) 0%, rgba(239,229,174,1) 100%);
        }

        .dynamic-content {
          font-size: 1.4rem;

          @include media-breakpoint-up(md) {
            font-size: 1.8rem;
          }

          p {
            font-size: 1.4rem;

            @include media-breakpoint-up(md){
              font-size: 1.8rem;
            }
          }

          ul, ol {
            margin-left: 32px;
            padding: 0;
          }

          ul {
            list-style: disc;
          }

          a {
            color: $color-red;
            font-weight: $font-weight-bold;
            @include trans(color);

            &:hover {
              color: $color-dark-red;
            }
          }
        }
      }
    }
  }

  &--three {

    @include media-breakpoint-up(lg) {
      padding-top: 80px;
      padding-bottom: 100px;
    }

    .faq {
      @include media-breakpoint-up(md) {
        margin: 52px auto 0;
      }

      .accordion {
        border-radius: 0;

        @include media-breakpoint-up(md) {
          margin-bottom: 16px;
        }

        &__header {
          border: 0;
          padding: 14px 32px;
          background-color: #dfdfdf;
          @include trans(background-color, box-shadow);

          @include media-breakpoint-up(md) {
            padding: 16px 32px;
          }

          &:hover {
            background-color: #cfcfcf;
          }

          &.active {
            background-color: #cfcfcf;
            border-radius: 0;
          }
        }

        &__title {
          color: $color-black;

          @include media-breakpoint-up(md) {
            font-size: 2.2rem;
            line-height: 27px;
          }
        }

        &__body {
          border: none !important;
          box-shadow: inset 0 0 0 1px #cfcfcf;
          margin-top: 0;
          background-color: $color-white;
        }

        &__indicator {
          svg {
            width: 20px;

            @include media-breakpoint-up(md) {
              width: 15px;
            }
          }
        }

        .dynamic-content {
          font-size: 1.4rem;

          @include media-breakpoint-up(md) {
            font-size: 1.8rem;
          }

          p {
            font-size: 1.4rem;

            @include media-breakpoint-up(md){
              font-size: 1.8rem;
            }
          }

          ul, ol {
            margin-left: 32px;
            padding: 0;
          }

          ul {
            list-style: disc;
          }

          a {
            color: $color-red;
            font-weight: $font-weight-bold;
            @include trans(color);

            &:hover {
              color: $color-dark-red;
            }
          }
        }
      }
    }

    #faq-toggler-button {
      max-width: 196px;
    }
  }
}