.info-section {
  background-color: $color-light-grey;

  @include media-breakpoint-up(lg) {
    padding: 85px 0 90px;
    background-image: url("../assets/static/info-bg.png");
    background-position: right -159px top -10px;
    background-size: 318px;
    background-repeat: no-repeat;
  }

  &__icons-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;

    @include media-breakpoint-up(lg) {
      max-width: 1820px;
      margin: auto;
    }
  }

  &__icon {
    width: 89px;
    height: 89px;
    animation: fly 1.5s infinite alternate linear;

    &:nth-child(even) {
      animation-direction: alternate-reverse;
    }

    &--delay {
      animation-delay: .5s;
    }

    @include media-breakpoint-up(lg) {
      width: 156px;
      height: 156px;
    }

    &--desktop {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }

  .section__title {
    line-height: 58px;
    font-size: 4rem;
    margin-top: 32px;

    @include media-breakpoint-up(md) {
      margin-bottom: 32px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 7.5rem;
      line-height: 108px;
    }
  }
}