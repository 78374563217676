/**
 * Podstawowe style
 */

*, *::before, *::after {
  box-sizing: border-box !important;
}

html {
  scroll-behavior: smooth;
  font-size: $base-font-size;
}

body {
  background-color: $color-white;
  color: $text-color;
  font-family: $font-family;
  line-height: 1.2;
  position: relative;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $color-white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-dark-green;
    @include trans(background-color);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $color-green;
  }

  &.open {
    overflow: hidden;
  }

  &.main {
    &::-webkit-scrollbar-track {
      background: $color-white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-red;
      @include trans(background-color);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $color-dark-red;
    }
  }

  &.three {
    &::-webkit-scrollbar-track {
      background: $color-white;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $color-gold;
      @include trans(background-color);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: lighten($color-gold, 10%);
    }
  }
}

a {
  word-break: break-word;
  @include trans(color);
  color: $color-white;

  &, &:active {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
    color: $color-green;
  }
}

img, svg {
  width: 100%;
  max-width: 100%;
  height: auto;
}

p,
ul {
  margin-bottom: 0;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
}

::selection {
  background: $selection-color;
}

button:focus {
  outline-color: $input-btn-focus-color;
}

button {
  outline: 0 !important;
}


