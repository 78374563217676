.rules-section {
  padding-top: 56px;
  padding-bottom: 72px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 115px;
  }

  .section__title {
    margin-bottom: 8px;
  }

  .rules {

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(4, 1fr);
    }

    .rule {
      background-color: $color-light-green;
      border-radius: 8px 8px 120px 8px;
      color: $color-white;
      width: 100%;
      max-width: 304px;
      min-height: 393px;
      margin: 0 auto 32px;
      padding: 36px 22px 22px;

      @include media-breakpoint-up(xl) {
        margin-bottom: 56px;
      }

      &:hover {
        .anim-bubble, .anim-rotate {
          animation-play-state: running;
        }
      }

      &__number {
        max-width: 93px;
        margin: 0 auto 24px;
      }

      &__text {
        font-size: 2rem;
        font-weight: $font-weight-bold;
        line-height: normal;
        margin-bottom: 24px;
        position: relative;
        z-index: 1;

        &--red {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            top: 1px;
            right: -5px;
            bottom: -1px;
            left: -5px;
            background-color: $color-red;
            z-index: -1;
          }
        }

        &--red-middle {
          &::after {
            right: -8px;
            left: -9px;
          }
        }
      }

      &__button {
        margin-top: 38px;
      }

      .anim-bubble, .anim-rotate {
        animation-play-state: paused;
      }
    }
  }

  .extra-info {
    font-size: 1.6rem;
    line-height: 21px;
    margin-bottom: 11px;

    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
      line-height: 22px;
      margin-bottom: 12px;
    }

    &--small {
      font-size: 1.4rem;
      line-height: normal;
      margin: 0 15px 11px;

      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
        line-height: 22px;
      }
    }

    &--margin {
      margin-bottom: 24px;
    }

    &--green {
      color: $color-light-green;
      font-weight: $font-weight-bold;
    }
  }

  .button {
    max-width: 304px;
    margin: 36px auto 0;

    @include media-breakpoint-up(lg) {
      margin: 46px auto 0;
    }
  }

  &__spacer {
    height: 1px;
    width: 100%;
    background: linear-gradient(270deg, rgba(178,140,46,1) 0%, rgba(225,203,94,1) 100%);
    margin-bottom: 24px;
  }

  &--main {
    @include media-breakpoint-up(lg) {
      background-image: url("../assets/static/download-bg.png"), url("../assets/static/download-bg.png");
      background-repeat: no-repeat, no-repeat;
      background-position: -159px center, right -159px top 70px;
      background-size: 318px, 318px;
    }

    .section__title {
      @include media-breakpoint-up(md) {
        margin-bottom: 26px;
      }
    }

    .rules {
      margin-top: 40px;

      @include media-breakpoint-up(xl) {
        margin-top: 60px;
        margin-bottom: 5px;
      }

      .rule {
        background-color: unset;
        color: $color-black;
        min-height: unset;
        padding: 0;

        &:not(:last-child){
          margin-bottom: 64px;
        }

        @include media-breakpoint-up(xl) {
          margin-bottom: 56px;
        }

        &__number {
          max-width: 257px;
          margin: 0 auto 16px;
        }

        &__text {
          font-weight: $font-weight-normal;
          line-height: 120%;
          margin-bottom: 16px;

          a {
            color: $color-red;
            font-weight: $font-weight-bold;

            &:hover {
              color: $color-dark-red;
            }
          }
        }

        &__button {
          font-size: 1.8rem;
          margin-top: 0;

          @include media-breakpoint-up(lg) {
            margin: 22px auto 0;
          }
        }
      }
    }

    .extra-info {
      color: $color-black;

      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }
    }
  }

  &--three {
    background-color: $color-light-grey;
    padding-bottom: 76px;

    @include media-breakpoint-up(lg) {
      padding-top: 80px;
      padding-bottom: 115px;
    }

    .container {
      @include media-breakpoint-up(xl) {
        max-width: 1425px;
      }
    }

    .section__title {
      margin-bottom: 24px;

      @include media-breakpoint-up(md) {
        margin-bottom: 26px;
      }
    }

    .rules {
      margin-top: 70px;

      @include media-breakpoint-up(xl) {
        margin-top: 67px;
        margin-bottom: 5px;
      }

      .rule {
        background-color: unset;
        color: $color-black;
        min-height: unset;
        padding: 0;
        margin-bottom: 62px;

        &:not(:last-child){
          margin-bottom: 67px;

          @include media-breakpoint-up(xl) {
            margin-bottom: 56px;
          }
        }

        @include media-breakpoint-up(xl) {
          margin-bottom: 56px;
        }

        &__number {
          max-width: 304px;
          margin: 0 auto 16px;
        }

        &__text {
          font-weight: $font-weight-normal;
          line-height: 120%;
          margin-bottom: 16px;

          a {
            color: $color-red;
            font-weight: $font-weight-bold;

            &:hover {
              color: $color-dark-red;
            }
          }
        }

        &__button {
          font-size: 1.6rem;
          margin-top: 0;
          max-width: 251px;

          @include media-breakpoint-up(lg) {
            margin: 15px auto 0;
          }

          &--small {
            max-width: 198px;
          }
        }
      }
    }

    .extra-info {
      color: $color-black;
      margin-bottom: 22px;

      @include media-breakpoint-up(lg) {
        margin: 0 auto 24px;
        max-width: 1250px;
      }
    }

    .rules-section__spacer {
      background-color: $color-gold;
      margin:0 auto 22px;
      max-width: 1312px;
    }

    .button {
      max-width: 177px;

      @include media-breakpoint-up(md) {
        margin: 30px auto 0;
      }
    }
  }
}