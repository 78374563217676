.awards-section {
  background: url("../assets/awards-bg.jpg") no-repeat center center/cover;
  padding-bottom: 52px;

  @media only screen and (-o-min-device-pixel-ratio: 5/4),
  only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-device-pixel-ratio: 1.25),
  only screen and (min-resolution: 1.25dppx),
  (min-resolution: 72dpi) {
    background: url("../assets/awards-bg@2x.jpg") no-repeat center center/cover;;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 58px;
  }

  .wrapper {
    max-width: 1920px;
    margin: auto;
  }

  .section__title {
    margin-bottom: 15px;
  }

  .main-award {
    position: relative;
    text-align: left;

    &__wrapper {
      background: url("../assets/static/award-main-bg.svg") no-repeat center center/contain;
      max-width: 320px;
      margin: auto;
      width: 100%;
      position: relative;
      z-index: 2;

      @include media-breakpoint-up(md) {
        max-width: 500px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 760px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 1306px;
      }

      @media (min-width: 1710px) {
        margin-left: 165px;
        margin-top: -78px;
      }
    }

    &__text-wrapper {
      color: $color-white;
      font-style: italic;
      margin-left: 104px;
      padding: 17px 0;

      @include media-breakpoint-up(md) {
        margin-left: 165px;
        padding: 45px 0;
      }

      @include media-breakpoint-up(lg) {
        margin-left: 250px;
      }

      @include media-breakpoint-up(xl) {
        padding: 132px 0 145px;
        margin-left: 430px;
      }
    }

    &__text {
      font-size: 1.86rem;
      letter-spacing: 0.3px;
      line-height: 150%;

      @include media-breakpoint-up(md) {
        font-size: 2rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 3.5rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 7.6rem;
      }

      &--big {
        font-weight: $font-weight-bold;
        font-size: 1.96rem;
        letter-spacing: unset;
        line-height: 150%;

        @include media-breakpoint-up(md) {
          font-size: 2.96rem;
          margin-bottom: -5px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 4.4rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 8rem;
          margin-bottom: -20px;
        }
      }

      &--bold {
        font-weight: $font-weight-bold;
        margin-top: -12px;
        letter-spacing: 0.4px;

        @include media-breakpoint-up(xl) {
          margin-top: -40px;
        }
      }
    }

    &__image-wrapper {
      transform: scale(1.5) translate(-35px, -46px);
      max-width: 360px;
      margin: auto;
      z-index: 1;
      position: relative;

      @include media-breakpoint-up(md) {
        transform: scale(1) translate(30px, -203px);
        max-width: 780px;
        z-index: 2;
      }

      @include media-breakpoint-up(lg) {
        transform: scale(1) translate(100px, -238px);
        max-width: 905px;
      }

      @include media-breakpoint-up(xl) {
        transform: scale(1) translate(280px, -455px);
        max-width: 1368px;
      }

      @media (min-width: 1710px) {
        transform: scale(1) translate(280px, -540px);
        margin-left: 273px;
      }
    }
  }

  .awards {
    margin-top: -25px;
    position: relative;
    z-index: 3;

    @include media-breakpoint-up(md) {
      margin-top: -245px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -300px;
    }

    @include media-breakpoint-up(xl) {
      display: flex;
      max-width: 1300px;
      margin: -570px auto 0;
      justify-content: center;
    }

    @media (min-width: 1710px) {
      max-width: 1700px;
      margin: -710px auto 0;
    }
  }

  .side-award {
    position: relative;
    text-align: left;
    margin-bottom: 5px;

    @include media-breakpoint-up(xl) {
      width: 100%;
    }

    &__wrapper {
      background: url("../assets/static/award-side-bg-mobile.svg") no-repeat center center/contain;
      max-width: 320px;
      margin: auto;
      width: 100%;

      @include media-breakpoint-up(md) {
        background: url("../assets/static/award-side-bg.svg") no-repeat center center/contain;
        max-width: 500px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 550px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 655px;
      }

      @media (min-width: 1710px) {
        max-width: 850px;
      }
    }

    &__text-wrapper {
      color: $color-white;
      font-style: italic;
      margin-left: 104px;
      padding: 16px 0 28px;

      @include media-breakpoint-up(md) {
        margin-left: 145px;
        padding: 26px 0 48px;

        br {
          display: none;
        }
      }

      @include media-breakpoint-up(lg) {
        margin-left: 163px;
        padding: 29px 0 55px;
      }

      @include media-breakpoint-up(xl) {
        margin-left: 190px;
        padding: 50px 0 70px;
      }

      @media (min-width: 1710px) {
        margin-left: 240px;
        padding: 45px 0 68px;
      }
    }

    &__text {
      font-size: 1.2rem;
      letter-spacing: 0.3px;
      line-height: 150%;
      margin-bottom: -5px;

      @include media-breakpoint-up(md) {
        font-size: 1.6rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 2rem;
        margin-bottom: -3px;
      }

      @media (min-width: 1710px) {
        font-size: 2.8rem;
        line-height: 130%;
        margin-bottom: 7px;
      }

      &:last-child {
        line-height: 13px;
        margin-top: 5px;
      }

      &--big {
        font-weight: $font-weight-bold;
        font-size: 1.74rem;
        letter-spacing: unset;
        line-height: 150%;

        @include media-breakpoint-up(md) {
          font-size: 2.74rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 3.74rem;
        }

        @media (min-width: 1710px) {
          font-size: 4.66rem;
          margin-bottom: 3px;
        }
      }
    }
  }

  .awards-extra-info {
    max-width: 315px;
    margin: 20px auto 0;

    @include media-breakpoint-up(md) {
      max-width: 460px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 1290px;
    }

    &__mobile {
      @include media-breakpoint-up(xl) {
        display: none;
      }
    }
    &__desktop {
      @include media-breakpoint-down(xl) {
        display: none;
      }
    }
  }

  &--main {
    background: linear-gradient(0deg, rgba(56,38,24,1) 0%, rgba(131,79,40,1) 30%, rgba(167,109,46,1) 60%, rgba(213,159,62,1) 100%);
    padding: 55px 0 75px;

    .main-award {
      &__main-image-wrapper {
        position: relative;
        height: 128vw;
        max-height: 820px;

        @include media-breakpoint-up(lg) {
          height: 610px;
        }

        @include media-breakpoint-up(xl) {
          height: 673px;
        }

        img {
          position: absolute;
          left: 50%;
          transform: translate(-58%, calc(-224px + 17vw));
          max-width: 775px;
          width: calc(300px + 73vw);

          @include media-breakpoint-up(md) {
            transform: translate(-58%, calc(-224px + 1vw));
            max-width: 890px;
          }

          @include media-breakpoint-up(lg) {
            transform: translate(-53%, -200px);
            max-width: unset;
            width: 1350px;
          }

          @include media-breakpoint-up(xl) {
            transform: translate(-55%, -424px);
            width: 2050px;
          }
        }
      }
    }
  }
}

.awards-section-three {
  padding-bottom: 72px;

  @include media-breakpoint-up(xl) {
    padding: 0;
  }

  .content {
    @include media-breakpoint-up(xl) {
      display: flex;
      align-items: stretch;
      max-width: 1920px;
      margin: auto;
    }

    &__wrapper {
      position: relative;
      isolation: isolate;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include media-breakpoint-up(xl) {
        width: 100%;
        padding: 80px 0 0 134px;
      }
    }

    &__bg-container {
      display: none;

      @include media-breakpoint-up(xl) {
        display: block;
        background-size: 1035px;
        background-position: right -51px top -48px;
        background-repeat: no-repeat;
        height: 762px;
        width: 100%;
        background-image: url("../assets/hero/hero-slider-1.jpg");
        max-width: 756px;

        @media only screen and (-o-min-device-pixel-ratio: 5/4),
        only screen and (-webkit-min-device-pixel-ratio: 1.25),
        only screen and (min-device-pixel-ratio: 1.25),
        only screen and (min-resolution: 1.25dppx),
        (min-resolution: 72dpi) {
          background-image: url("../assets/hero/hero-slider-1@2x.jpg");
        }

        @media only screen and (-o-min-device-pixel-ratio: 9/4),
        only screen and (-webkit-min-device-pixel-ratio: 2.25),
        only screen and (min-device-pixel-ratio: 2.25),
        only screen and (min-resolution: 2.25dppx),
        (min-resolution: 300dpi) {
          background-image: url("../assets/hero/hero-slider-1@3x.jpg");
        }
      }
    }

    &__line {
      display: none;

      @include media-breakpoint-up(xl) {
        position: absolute;
        display: block;
        right: -125px;
        top: 0;
        bottom: 0;
        height: 100%;
        z-index: -1;
      }
    }
  }

  .main-award {
    width: 100%;

    &__main-image-wrapper {
      padding: 20px 34px 0;
      max-width: 520px;
      margin: auto;

      @include media-breakpoint-up(md) {
        padding-top: 50px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 950px;
      }

      @include media-breakpoint-up(xl) {
        width: 100%;
        max-width: 1073px;
        padding: 95px 0 0;
      }
    }
  }
}