.header {
  .navbar {
    padding: 0;
    background: $color-white;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.25);

    @include media-breakpoint-up(xl) {
      box-shadow: none;
    }

    .container {
      min-height: $navbar-height;
      @include trans(min-height);
      max-width: unset !important;
      justify-content: flex-end;

      @include media-breakpoint-up(xl) {
        min-height: 122px;
        max-width: 1586px !important;
      }
    }

    .navbar-brand {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      padding: 0;
      position: absolute;
      left: 20px;
      top: 0;
      min-height: $navbar-height;
      transform-origin: top left;
      @include trans(min-height, transform, opacity);
      z-index: 1;

      @include media-breakpoint-up(xl) {
        min-height: 122px;
        padding-left: 0;
        position: absolute;
        left: -20px;
        width: 250px;
        top: 0;
      }

      @media (min-width: 1420px) {
        width: 290px;
      }

      svg {
        width: 100%;
        transform-origin: top left;
        @include trans(transform);
      }
    }

    &.scrolled {
      box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.25);

      .container, .navbar-brand {
        min-height: 60px;

        @include media-breakpoint-up(xl) {
          min-height: 70px;
        }
      }

      .navbar-brand {
        svg {
          transform: scale3d(0.85, 0.85, 0.85);

          @include media-breakpoint-up(xl) {
            transform: scale3d(0.55, 0.55, 0.55);
          }
        }
      }
    }

    .hamburger {
      padding: 13px 15px;
    }

    .navbar-toggler {
      z-index: 2;
      padding: 13px 4px;

      &:focus {
        box-shadow: 0 0 0 1px $color-red;
      }
    }

    .navbar-nav {
      background-color: $color-white;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      padding: 40px 20px 140px;
      overflow-y: auto;
      max-height: 100vh;

      @include media-breakpoint-up(xl) {
        padding: 0;
        margin-right: 0;
        background-color: transparent;
        justify-content: flex-end;
        overflow: visible;
      }

      .nav-item {
        width: 100%;
        margin: 4px auto;

        @include media-breakpoint-up(xl) {
          width: unset;
          padding: 0;
          margin: 0;
          position: relative;

          &:not(:nth-last-child(2)) {
            margin-right: 8px;
          }

          &:nth-last-child(2) {
            margin-left: 0;
          }
        }

        @media (min-width: 1500px) {
          &:not(:nth-last-child(2)) {
            margin-right: 20px;
          }
        }

        &--socials {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          padding: 32px 0 36px;

          @include media-breakpoint-up(xl) {
            padding: 0;
          }

          a {
            svg {
              width: 38px;
              height: 38px;

              circle {
                @include trans(fill);
              }

              @include media-breakpoint-up(xl) {
                width: 24px;
                height: 24px;
              }
            }

            &:hover {
              svg circle{
                fill: $color-dark-red;
              }
            }
          }
        }

        &--mobile {
          display: flex;
          justify-content: center;
          padding-top: 36px;
          border-top: 1px solid $color-light-grey;

          img {
            max-width: 129px;
            margin: auto;
          }

          @include media-breakpoint-up(xl) {
            display: none;
          }
        }

        .dropdown-toggle {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 14px;

          @include media-breakpoint-up(xl) {
            gap: 6px;
          }

          svg {
            width: 20px;
            height: 12px;
            @include trans(transform);
          }

          &::after {
            display: none;
          }

          &.show {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .dropdown-menu {
          padding: 0;
          margin: 0;
          border: none;

          @include media-breakpoint-up(xl) {
            right: 0;
            left: unset;
            box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.25);
          }
        }

        .dropdown-item {
          background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(131,79,40,1) 40%, rgba(167,109,46,1) 70%, rgba(213,159,62,1) 100%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;

          &--black {
            color: $color-black;
            background: unset;
            background-clip: unset;
            -webkit-text-fill-color: unset;
            @include trans(color);

            &:hover {
              color: $color-dark-red;
            }
          }
        }
      }

      .nav-spacer {
        height: 1px;
        width: 100%;
        background: linear-gradient(270deg, rgba(178,140,46,1) 0%, rgba(225,203,94,1) 100%);
        flex-shrink: 0;

        &--margin {
          margin-top: 16px;
        }

        @include media-breakpoint-up(xl) {
          display: none;
        }
      }

      .nav-link {
        color: $color-red;
        padding: 16px;
        font-family: $font-family;
        font-size: 1.8rem;
        line-height: normal;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        @include trans(color);

        &.active,
        &:hover {
          color: $color-dark-red;
        }

        &--black {
          color: $color-black;
        }

        &--button {
          width: fit-content;
          border-radius: 4px;
          background-color: $color-green;
          color: $color-white;
          @include trans(background-color, color);

          &:hover,
          &.active {
            background-color: $color-dark-green;
            color: $color-white;
          }

          &--red {
            background-color: $color-red;

            &:hover,
            &.active {
              background-color: $color-dark-red;
            }
          }
        }
      }
    }
  }

  &--main {
    .navbar {
      .navbar-nav {
        .nav-item {
          &--mobile {
            border-top: none;
          }
        }
        .nav-link {
          &--button {
            border-radius: 24px;
          }
        }
      }
    }
  }
}