.challenge-section {
  background-image: url("../assets/challenge-bg-mobile.jpg");
  background-size: 434px;
  background-repeat: no-repeat;
  background-position: center top;
  padding-bottom: 32px;

  @media only screen and (-o-min-device-pixel-ratio: 5/4),
  only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-device-pixel-ratio: 1.25),
  only screen and (min-resolution: 1.25dppx),
  (min-resolution: 72dpi) {
    background-image: url("../assets/challenge-bg-mobile@2x.jpg");
  }

  @media only screen and (-o-min-device-pixel-ratio: 9/4),
  only screen and (-webkit-min-device-pixel-ratio: 2.25),
  only screen and (min-device-pixel-ratio: 2.25),
  only screen and (min-resolution: 2.25dppx),
  (min-resolution: 300dpi) {
    background-image: url("../assets/challenge-bg-mobile@3x.jpg");
  }

  @media only screen and (min-width: 432px) {
    background-size: 536px;
  }

  @media only screen and (min-width: 534px) {
    background-size: 611px;
  }

  @media only screen and (min-width: 610px) {
    background-size: 700px;
  }

  @media only screen and (min-width: 700px) {
    background-size: 992px;
    background-position: center top -386px;
  }

  @include media-breakpoint-up(lg) {
    background: url("../assets/challenge-bg.jpg") center center no-repeat, linear-gradient(112deg, rgba(227,106,6,1) 50%, rgba(215,224,36,1) 50%) center no-repeat;
    background-size: 2084px, cover;
    padding-top: 75px;
    padding-bottom: 52px;

    @media only screen and (-o-min-device-pixel-ratio: 5/4),
    only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 1.25dppx),
    (min-resolution: 72dpi) {
      background: url("../assets/challenge-bg@2x.jpg") center center no-repeat, linear-gradient(112deg, rgba(227,106,6,1) 50%, rgba(215,224,36,1) 50%) center no-repeat;
      background-size: 2084px, cover;
    }

    @media only screen and (-o-min-device-pixel-ratio: 9/4),
    only screen and (-webkit-min-device-pixel-ratio: 2.25),
    only screen and (min-device-pixel-ratio: 2.25),
    only screen and (min-resolution: 2.25dppx),
    (min-resolution: 300dpi) {
      background: url("../assets/challenge-bg@3x.jpg") center center no-repeat, linear-gradient(112deg, rgba(227,106,6,1) 50%, rgba(215,224,36,1) 50%) center no-repeat;
      background-size: 2084px, cover;
    }
  }

  .content {
    @include media-breakpoint-up(lg) {
      display: flex;
      max-width: 1920px;
      margin: auto;
    }

    &__item {
      max-width: 310px;
      margin: auto;

      @media only screen and (min-width: 432px) {
        max-width: 435px;
      }

      @media only screen and (min-width: 534px) {
        max-width: 535px;
      }

      @media only screen and (min-width: 610px) {
        max-width: 600px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 741px;
      }

      &--1 {
        margin-bottom: 85px;

        @media only screen and (min-width: 610px) {
          margin-bottom: 115px;
        }

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
          margin-top: 0;
        }

        .main-image {
          transform: translate(-11px, -15px);

          @include media-breakpoint-up(xl) {
            transform: translate(-14px, -40px);
          }
        }

        .content__svg-text {
          max-width: 190px;
          margin: -30px auto 6px;
          z-index: 4;
          position: relative;

          @media only screen and (min-width: 610px) {
            max-width: 250px;
          }

          @include media-breakpoint-up(xl) {
            max-width: 455px;
            transform: translateX(30px);
            margin: -72px auto 6px;
          }
        }

        .content__text {
          @include media-breakpoint-up(xl) {
            transform: translateX(20px);
          }
        }
      }

      &--2 {
        .main-image {
          transform: translate(-12px, -53px);

          @include media-breakpoint-up(lg) {
            transform: translate(-12px, -8px);
          }

          @include media-breakpoint-up(xl) {
            transform: translate(-72px, -20px);
          }
        }

        .content__svg-text {
          max-width: 150px;
          margin: -75px auto 6px;
          z-index: 4;
          position: relative;

          @media only screen and (min-width: 610px) {
            max-width: 200px;
          }

          @include media-breakpoint-up(lg) {
            margin: -28px auto 6px;
          }

          @include media-breakpoint-up(xl) {
            max-width: 355px;
            transform: translateX(-57px);
            margin: -66px auto 6px;
          }
        }

        .content__text {
          @include media-breakpoint-up(xl) {
            transform: translateX(-52px);
          }
        }
      }
    }

    &__image-wrapper {
      animation: fly 5s infinite ease-in-out alternate;
      position: relative;

      &--delay {
        animation-delay: 2.5s;
      }

      .main-picture {
        z-index: 2;
        position: relative;
      }

      .anim-wrapper{
        position: absolute;

        &--1 {
          top: 125px;
          left: 94px;
        }
        &--2 {
          top: 67%;
          left: 76%;
        }
        &--3 {
          top: 50%;
          left: 182px;
        }
        &--4 {
          top: 49%;
          left: 91px;
        }
        &--5 {
          top: 47%;
          left: 56%;
        }
      }

      .anim-item {
        position: absolute;
        width: 18px;
        animation: fly2 6s infinite linear alternate;
        z-index: 3;

        @media only screen and (min-width: 432px) {
          width: 30px;
        }

        @include media-breakpoint-up(md) {
          width: 60px;
        }
        &--delay {
          animation-delay: 1s;
        }

        &--short {
          animation: fly2 5s infinite linear alternate;
        }
      }

      .anim-fly {
        position: absolute;
        top: 5px;
        left: 47px;
        width: 24px;
        animation: fly 3s infinite ease-in-out alternate;
        z-index: 3;

        @include media-breakpoint-up(sm) {
          width: 30px;
          top: 37px;
          left: 70px;
        }

        @include media-breakpoint-up(md) {
          width: 44px;
        }

        &--2 {
          top: -40px;
          left: 210px;
          animation-duration: 4s;

          @include media-breakpoint-up(sm) {
            top: -4px;
            left: 275px;
          }

          img {
            transform: rotate(45deg);
          }
        }
      }
    }

    &__text {
      color: $color-white;
      font-size: 1.2rem;
      line-height: 15px;
      position: relative;
      z-index: 5;

      @media only screen and (min-width: 610px) {
        font-size: 1.5rem;
        line-height: 18px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 2.8rem;
        line-height: 34px;
        margin-top: 10px;
      }
    }
  }
}
