.winners-section {
  background-color: $color-light-green;
  padding: 60px 0 76px;

  @include media-breakpoint-up(lg) {
    padding-top: 57px;
    padding-bottom: 125px;
  }

  .section__title {
    @include media-breakpoint-up(md) {
      margin-bottom: 50px;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #bdd67a;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $color-dark-green;
    @include trans(background-color);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: $color-green;
  }

  .winners {
    max-width: 1095px;
    margin: 0 auto;
    max-height: 480px;
    overflow-y: auto;
    z-index: 10;
    position: relative;

    &__wrapper {
      position: relative;
      padding-right: 6px;

      @include media-breakpoint-up(md) {
        padding-right: 10px;
      }
    }

    &__item {
      padding: 12px 16px;
      display: flex;
      gap: 10px;
      height: 80px;
      justify-content: center;
      align-items: center;
      text-align: center;


      @include media-breakpoint-up(lg) {
        padding: 13px 32px;
        height: 80px;
      }

      &:nth-child(even) {
        background: #bdd67a;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #E0E0E0;
      }

      &--header {
        position: sticky;
        top: 0;
        width: 100%;
        background-color: $color-red;
        border-bottom: 1px solid #E0E0E0 !important;
        z-index: 10;

        @include media-breakpoint-up(lg) {
          padding: 11px 32px;
        }
      }

      &--no-winners {
        height: auto;
      }
    }

    &__date, &__winner, &__award-type, &__award {
      color: $color-white;
      font-size: 1.8rem;
      font-weight: $font-weight-bold;

      @include media-breakpoint-up(sm) {
        font-size: 2rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 2.4rem;
      }

      &--win {
        color: $color-dark-grey;
        font-size: 1.6rem;

        @include media-breakpoint-up(sm) {
          font-size: 1.6rem;
        }

        @include media-breakpoint-up(lg) {
          font-size: 1.8rem;
        }
      }
    }

    &__inner-wrapper {
      flex-basis: 50%;

      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        margin-left: 15px;

        & > div {
          flex-basis: 50%;
        }
      }
    }

    .no-winners {
      padding: 15px 10px;
      font-size: 2rem;
      color: $color-white;
      font-weight: $font-weight-bold;

      @include media-breakpoint-up(lg) {
        padding: 40px 10px;
        font-size: 3rem;
      }

      &--green, a {
        color: $color-dark-green;
        @include trans(color);

        &:hover {
          color: $color-green;
        }
      }
    }

  }

  .main-winner {
    text-align: center;
    margin: 20px 0 35px;
    font-size: 2rem;
    color: $color-red;
    line-height: 22px;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(lg) {
      font-size: 3rem;
      line-height: 30px;
    }
  }

  &--main {
    background-image: linear-gradient(0deg, rgba(56, 38, 24, 1) 0%, rgba(131, 79, 40, 1) 30%, rgba(167, 109, 46, 1) 60%, rgba(213, 159, 62, 1) 100%);

    @include media-breakpoint-up(lg) {
      background-image: url("../assets/static/main/winners-bg.png"), linear-gradient(0deg, rgba(56, 38, 24, 1) 0%, rgba(131, 79, 40, 1) 30%, rgba(167, 109, 46, 1) 60%, rgba(213, 159, 62, 1) 100%);
      background-repeat: no-repeat, no-repeat;
      background-position: top -85px center, center;
      background-size: 2130px, cover;
    }

    ::-webkit-scrollbar-track {
      background: #b5744c;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $color-white;
      @include trans(background-color);
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: darken($color-white, 10%);
    }

    .win-dates {
      margin-bottom: 46px;

      &--desktop {
        display: none;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;

        @include media-breakpoint-up(md) {
          display: flex;
        }
      }

      &--mobile {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      &__date {
        background-color: $color-white;
        border-radius: 6px;
        color: $color-black;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-basis: 30%;
        height: 42px;
        font-size: 1.8rem;
        font-weight: $font-weight-bold;
        @include trans(background-color);

        &:hover {
          background-color: darken($color-white, 10%);
          cursor: pointer;
        }

        @include media-breakpoint-up(md) {
          width: 82px;
          flex-basis: unset;
        }

        &.active {
          color: $color-white;
          background-color: $color-red;
        }
      }

      .winner-dates-slider {
        max-width: 504px;
      }

      .swiper-wrapper {
        padding-bottom: 40px;
      }

      .swiper-pagination {
        bottom: 0;

        .swiper-pagination-bullet {
          width: 14px;
          height: 14px;
          background-color: $color-white;
          opacity: 0.3;
          margin: 0 12px;

          &-active {
            opacity: 1;
          }
        }
      }

      .swiper-button-prev, .swiper-button-next {
        bottom: -10px;
        top: unset;

        &:after {
          display: none;
        }
      }

      .swiper-button-prev {
        left: 25%;
        transform: translateX(-50%);
      }

      .swiper-button-next {
        right: 18%;
        transform: translateX(-70%);

        svg {
          transform: rotate(-180deg);
        }
      }

      .swiper-slide {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
        padding: 0 18px;

        @include media-breakpoint-down(sm) {
          &:first-child {
            padding-left: 18px;
            padding-right: 0;
          }
          &:last-child {
            padding-left: 0;
            padding-right: 18px;
          }
        }
      }
    }

    .winners {
      max-width: 1088px;
      max-height: 560px;

      @include media-breakpoint-up(lg) {
        max-height: 926px;
      }

      &__list {
        display: none;

        &.active {
          display: block;
        }
      }

      &__item {
        background-color: $color-white;

        padding: 12px 16px;
        display: flex;
        gap: 10px;
        height: 80px;
        justify-content: center;
        align-items: center;
        text-align: center;


        @include media-breakpoint-up(lg) {
          padding: 13px 12px 13px 6px;
          height: 72px;
        }

        &:nth-child(even) {
          background: $color-light-red;
        }

        &:not(:last-child) {
          border-bottom: none;
        }

        &--header {
          background-color: $color-red;
          border-bottom: none !important;

          @include media-breakpoint-up(lg) {
            padding: 8px 12px;
            height: 62px;
          }
        }

        &--no-winners {
          background-image: linear-gradient(0deg, rgba(56, 38, 24, 1) 0%, rgba(131, 79, 40, 1) 30%, rgba(167, 109, 46, 1) 60%, rgba(213, 159, 62, 1) 100%);
          border-radius: 0 0 6px 6px;
          height: unset;
        }
      }

      &__date, &__winner, &__number {
        color: $color-white;
        font-size: 2rem;
        font-weight: $font-weight-bold;

        @include media-breakpoint-up(md) {
          font-size: 2.2rem;
        }

        &--win {
          color: $color-black;
          font-weight: $font-weight-bold;
          font-size: 1.8rem;
        }
      }

      &__inner-wrapper {
        flex-basis: 25%;

        @include media-breakpoint-up(md) {
          flex-basis: 70px;
          display: flex;
          align-items: center;
          margin-left: 15px;

          & > div {
            flex-basis: 50%;
          }
        }

        &--wide {
          flex-grow: 1;
        }
      }

      .no-winners {
        font-size: 2.6rem;
        max-width: 740px;
        margin: auto;

        @include media-breakpoint-up(lg) {
          font-size: 5.4rem;
        }
      }
    }
  }
}

.winners-section-three {
  background-color: $color-light-grey;

  @include media-breakpoint-up(lg) {
    padding-top: 80px;
  }

  .section__title {
    position: relative;
    display: inline-block;
  }

  .title-decor {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      top: -66px;
      left: -115px;

      svg {
        width: 158px;
        height: 91px;
      }
    }
  }

  .winners {
    background: linear-gradient(90deg, #E3D36D 6.04%, #A88538 68.01%);
    padding-bottom: 35px;

    @include media-breakpoint-up(md) {
      margin-top: 50px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 1088px;
      margin: 46px auto 0;
    }

    &__title {
      background: #AA883B;
      box-shadow: 0 2px 2px 0 #00000029;
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: #AA883B;
      color: $color-white;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 21px;
      padding: 9px 9px 9px 32px;
      text-align: left;
      text-transform: uppercase;
      max-width: 218px;
      margin-bottom: 40px;
    }

    &__image-container {
      position: relative;
      max-width: 204px;
      height: 295px;
      margin: 0 auto 25px;
      background-color: $color-white;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(md) {
        max-width: 350px;
        height: 400px;
        padding: 10px;
      }

      @include media-breakpoint-up(lg) {
        flex-shrink: 0;
        margin: 0;
        width: 100%;
      }

      @include media-breakpoint-up(xl) {
        max-width: 405px;
        height: 520px;
      }
    }

    &__wrapper {
      padding: 0 10px 0 20px;
      max-height: 540px;
      overflow: auto;
      max-width: 501px;
      margin: auto;
      scrollbar-gutter: stable;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #b99c61;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $color-black;
        @include trans(background-color);
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: lighten($color-black, 10%);
      }

      @include media-breakpoint-up(lg) {
        margin: 0;
        align-self: flex-start;
        height: 748px;
        max-height: 748px;

        &--center {
          align-self: center;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      @include media-breakpoint-up(xl) {
        max-width: 530px;
        padding: 0 10px 0 0;
        width: 100%;
      }
    }

    &__info {
      font-size: 1.6rem;
      font-weight: 700;
      color: $color-black;
      line-height: 19px;

      @include media-breakpoint-up(md) {
        font-size: 2rem;
        line-height: 23px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 4rem;
        line-height: 48px;
      }

      span {
        display: inline-block;
        background-color: $color-white;
        padding: 2px 4px;

        @include media-breakpoint-up(xl) {
          padding: 6px 14px;
        }
      }

      &--big {
        font-size: 2.8rem;
        line-height: 34px;
        width: 220px;

        @include media-breakpoint-up(md) {
          font-size: 3.2rem;
          line-height: 37px;
          width: 320px;
        }

        @include media-breakpoint-up(xl) {
          font-size: 6.4rem;
          line-height: 78px;
          width: 503px;
        }
      }
    }

    .accordion {
      border-radius: 0;
      margin-bottom: 16px;

      &__header {
        border: 0;
        padding: 18px;
        background: #FFFFFF66;
        border-bottom: 1px solid $color-black;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        cursor: pointer;

        @include media-breakpoint-up(md) {
          height: 44px;
          padding: 4px;
        }

        &.active {
          .accordion__indicator {

            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }

      &__title {
        color: $color-black;
        font-size: 1.6rem;

        @include media-breakpoint-up(md) {
          font-size: 2rem;
          line-height: 24px;

          strong {
            font-size: 2.4rem;
            line-height: 29px;
          }
        }
      }

      &__body {
        border: none !important;
        margin-top: 0;
        background-color: transparent;
        max-height: 0;
        overflow: hidden;
        position: relative;
        @include trans(max-height);
      }

      &__indicator {

        svg {
          width: 16px;
          @include trans(transform);
        }
      }

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 6px;
        gap: 15px;
        border-bottom: 1px solid $color-black;

        @include media-breakpoint-up(md) {
          height: 80px;
          padding: 8px 16px;
        }

        &:nth-child(odd) {
          background: #FFFFFF33;
        }

        &--header {
          height: 50px;

          @include media-breakpoint-up(md) {
            height: 80px;
          }
        }
      }

      &__image {
        max-width: 43px;
        flex-shrink: 0;

        @include media-breakpoint-up(md) {
          max-width: 63px;
        }
      }

      &__name-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;

        &--header {
          width: 100%;
          justify-content: center;
        }
      }

      &__name {
        font-size: 1.2rem;
        line-height: 13px;
        font-weight: 700;
        color: $color-black;
        text-align: left;

        @include media-breakpoint-up(md) {
          font-size: 1.6rem;
          line-height: 19px;
        }

        &--header {
          font-size: 1.6rem;

          @include media-breakpoint-up(md) {
            font-size: 2.4rem;
            line-height: 29px;
          }
        }
      }

      &__winner {
        font-size: 1.4rem;
        line-height: 15px;
        font-weight: 700;
        color: $color-black;
        flex-basis: 47%;
        max-width: 100px;

        @include media-breakpoint-up(md) {
          font-size: 2rem;
          line-height: 24px;
          flex-basis: 60%;
          max-width: 200px;
          width: 100%;
          flex-shrink: 0;
        }

        @include media-breakpoint-up(lg) {
          flex-basis: 40%;
        }

        &--header {
          font-size: 1.6rem;

          @include media-breakpoint-up(md) {
            font-size: 2.4rem;
            line-height: 29px;
          }
        }
      }
    }

    &__container {
      background: linear-gradient(90deg, #E3D36D 6.04%, #A88538 68.01%);
      padding: 48px;
      display: none;
      align-items: center;
      border-radius: 0 4px 4px 4px;
      overflow: hidden;

      @include media-breakpoint-up(xl) {
        gap: 32px;
        padding: 48px 48px 48px 72px;
      }

      &.active {
        display: flex;
      }
    }

    &__tabs {
      display: flex;
    }

    &__tab {
      background-color: #e4e4e4;
      border-bottom: 1px solid $color-black;
      cursor: pointer;
      width: 218px;
      height: 62px;
      display: flex;
      align-items: center;
      padding-left: 32px;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 22px;
      text-align: left;
      text-transform: uppercase;
      overflow: hidden;
      @include trans(background-color, color);

      &:hover {
        background-color: darken(#e4e4e4, 10%);
      }

      &.active {
        background-color: $color-gold;
        color: $color-white;
        border-bottom: none;
      }

      &:first-child {
        border-top-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
      }
    }

    &--mobile {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        background: none;
        padding-bottom: 0;
      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    top: 250px;

    &::after {
      display: none;
    }

    svg {
      width: 8px;

      @include media-breakpoint-up(md) {
        width: 16px;
      }
    }
  }

  .swiper-button-prev {
    svg {
      transform: rotate(180deg);
    }
  }

  .winners-decor {
    display: none;

    @include media-breakpoint-up(xl) {
      display: block;
      position: absolute;

      &--1 {
        width: 290px;
        top: 449px;
        left: -201px;
      }

      &--2 {
        width: 353px;
        top: -149px;
        right: -164px;
      }
    }


  }
}