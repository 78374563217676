/**
 * Zmienne
 */
@font-face {
  font-family: Calibri;
  src: url("../assets/fonts/calibri-regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Calibri;
  src: url("../assets/fonts/calibri-bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Calibri;
  src: url("../assets/fonts/calibri-bold-italic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: Calibri;
  src: url("../assets/fonts/calibri-italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

$font-heinz: Calibri;
$font-asap: Calibri;
$font-family: $font-asap;
$base-font-size: 10px;

$enable-responsive-font-sizes: true;
$rfs-class: 'enable'; // responsive font tylko dla elementów z klasą .enable-responsive-font-size
$rfs-breakpoint: 576px;


$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

// Colors


$color-blue: #2438ac;

$color-light-grey: #EBEBEB;
$color-yellow: #FFB800;
$color-dark-yellow: #DFA100;

$link-color: $color-yellow;

// new colors
$color-white: #ffffff;
$color-black: #000000;
$color-red: #E7342C;
$color-dark-red: #b92a23;
$color-dark-grey: #2B2B2B;
$color-green: #066D52;
$color-dark-green: #055742;
$color-light-green: #ACCC59;
$color-light-grey: #F7F7F7;
$color-light-red: #ECE1D5;

$color-gold: #AA883B;
$color-orange: #E36705;

$text-color: $color-dark-grey;
$error-color: $color-red;
$selection-color: lighten($color-gold, 15%);

// Transitions
$transition-default-duration: 0.2s;
$transition-default-easing: ease;

// Paths
$gfx-path: "../img";
$fonts-path: "../fonts";

// Breakpoints

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1342px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 920px,
        xl: 1372px,
);

// Heights
$navbar-height: 71px;

// Mnożnik dla bootstrapowych klas do zarządzania margin i padding
$spacer: 10px;

$input-height: 60px;
$input-focus-border-color: $link-color;
$input-btn-focus-color: $link-color;
$input-border-width: 2px;
//$input-border-color: $border-color;
$input-btn-focus-box-shadow: inset 0 0 0 2px $input-btn-focus-color;
$form-grid-gutter-width: 20px;
$form-grid-gutter-width: 20px;
$custom-switch-width: 5.4rem;
$custom-control-indicator-size: 2rem;
$custom-switch-indicator-size: 18px;
$custom-control-indicator-active-bg: #FFF; //$selection-color;
$custom-control-indicator-active-border-color: $link-color; //$border-color;//$color-gold;
$custom-control-indicator-active-color: $color-red;
$custom-control-indicator-focus-border-color: $link-color; //$border-color;
$custom-control-indicator-checked-color: $link-color;
$custom-control-indicator-checked-bg: #FFF;
$custom-control-indicator-border-color: $border-color;
$custom-control-indicator-checked-border-color: $border-color;
$custom-control-indicator-border-width: 1px;

$modal-md: 570px;
$modal-inner-padding: 25px;
$modal-header-padding-x: 25px;
$modal-header-padding: 1.5rem $modal-header-padding-x 1rem;
$close-font-size: 2rem;
$close-font-weight: 300;
$close-color: $text-color;

$zindex-fixed: 1030;
