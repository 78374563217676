.download-section {
  background-color: #F7F7F7;
  padding-bottom: 75px;

  @include media-breakpoint-up(xl) {
    padding-top: 150px;
    padding-bottom: 188px;
  }

  .container {
    @media (min-width: 1480px) {
      max-width: 1465px;
    }
  }

  .content {
    text-align: left;
    color: $color-dark-grey;

    @media (min-width: 1480px) {
      margin-left: 100px;
    }

    &__title {
      font-size: 3.2rem;
      font-weight: $font-weight-bold;
      line-height: 110%;
      margin-bottom: 32px;

      @include media-breakpoint-up(md) {
        font-size: 5.4rem;
      }
    }

    &__subtitle {
      font-size: 2.6rem;
      font-weight: $font-weight-bold;
      line-height: normal;

      @include media-breakpoint-up(md) {
        font-size: 4rem;
        line-height: 110%;
      }
    }

    &__list {
      padding: 0;
      margin: 28px 0;
    }

    &__list-item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 17px;
      }

      svg {
        width: 24px;
        margin-right: 12px;
      }

      @include media-breakpoint-up(xl) {
        br {
          display: none;
        }
      }
    }

    &__text {
      font-size: 1.6rem;
      line-height: 110%;

      @include media-breakpoint-up(md) {
        font-size: 2rem;
      }

      .break-mobile {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      .break-desktop {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }

    &__links-wrapper {
      display: flex;
      align-items: center;
      gap: 12px
    }

    &__link {
      @include trans(transform);

      &:hover {
        transform: scale(1.02);
      }

      &:first-child {
        svg {
          width: 102px;
        }
      }

      &:last-child {
        svg {
          width: 113px;
        }
      }
    }
  }

  .qr-code-container{
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      top: 50%;
      z-index: -1;
      right: 0;
      transform: translateY(-50%);
    }

    @include media-breakpoint-up(xl) {
      top: 48%;
      right: 10px;
    }

    .qr-code {
      @include media-breakpoint-up(lg) {
        &__wrapper {
          width: 345px;
          position: relative;
        }

        &__image-wrapper {
          position: absolute;
          width: 280px;
          top: -105px;
          opacity: 0;
          transform: rotate(4deg);
          transition: transform 0.7s ease-in-out, opacity 0.7s ease-in-out;
          transition-delay: .2s;
        }

        &__qr-wrapper {
          border-radius: 16px;
          box-shadow: -12px 24px 40px 0px rgba(0, 0, 0, 0.10);
          overflow: hidden;
          position: absolute;
          width: 145px;
          bottom: 45px;
          left: -185px;
          opacity: 0;
        }

        &.aos-animate {
          .qr-code__image-wrapper {
          opacity: 1;
            transform: rotate(-8deg) translateX(-45px);
          }

          .qr-code__qr-wrapper {
            animation: scaleUp 0.7s forwards;
            animation-delay: .6s;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        &__wrapper {
          width: 500px;
        }

        &__image-wrapper {
          width: 344px;
          top: -53px;
        }

        &__qr-wrapper {
          width: 169px;
          bottom: 175px;
          left: -334px;
        }

        &.aos-animate {
          .qr-code__image-wrapper {
            transform: rotate(-8deg) translateX(-152px);
          }
        }
      }
    }
  }

  &--main {
    @include media-breakpoint-up(lg) {
      background: url("../assets/static/download-bg.png") no-repeat -159px center/318px;
    }

    @include media-breakpoint-up(xl) {
      padding-top: 145px;
      padding-bottom: 100px;
    }

    .content {
      &__title {
        line-height: 50px;
        margin-left: 15px;

        @include media-breakpoint-up(md) {
          line-height: 76px;
        }
      }
    }

    .qr-code-container{
      .qr-code {
        @include media-breakpoint-up(lg) {
          &__wrapper {
            height: 426px;
          }

          &__image-wrapper {
            top: -65px;
            right: 0;
          }

          &__qr-wrapper {
            left: -130px;
          }
        }

        @include media-breakpoint-up(xl) {
          &__wrapper {
            height: 617px;
          }

          &__image-wrapper {
            top: -90px;
            right: 55px;
          }

          &__qr-wrapper {
            left: -315px;
            width: 185px;
            bottom: 206px;
          }
        }
      }
    }
  }

  &--three {
    background-color: $color-white;

    @include media-breakpoint-up(xl) {
      padding-top: 145px;
      padding-bottom: 180px;
    }

    .content {
      &__title {
        line-height: 50px;
        margin-left: 15px;

        @include media-breakpoint-up(md) {
          line-height: 76px;
        }
      }
    }

    .qr-code-container{
      top: 55%;

      .qr-code {
        @include media-breakpoint-up(lg) {
          &__wrapper {
            height: 426px;
          }

          &__image-wrapper {
            top: -65px;
            right: -20px;
          }

          &__qr-wrapper {
            left: -110px;
          }
        }

        @include media-breakpoint-up(xl) {
          &__wrapper {
            height: 617px;
          }

          &__image-wrapper {
            top: -90px;
            right: 55px;
          }

          &__qr-wrapper {
            left: -315px;
            width: 185px;
            bottom: 265px;
          }
        }
      }
    }
  }
}