/**
 * Elementy powtarzające się w całym serwisie, gdzie wszędzie wyglądają tak samo np. buttony, komunikaty
 */

@import "hamburger";


.section {
  padding: 60px 0;
  position: relative;
  overflow: hidden;
  text-align: center;

  @include media-breakpoint-up(lg) {
    padding: 58px 0 120px;
  }

  &__title {
    font-size: 3.6rem;
    font-weight: $font-weight-bold;
    margin-bottom: 32px;
    line-height: normal;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
      font-size: 6.4rem;
    }

    &--main {
      line-height: 54px;
      isolation: isolate;

      @include media-breakpoint-up(md) {
        line-height: 85px;
      }
    }

    &--white {
      color: $color-white;
    }

    &--lower {
      text-transform: lowercase;
    }

    &--normal {
      text-transform: none;
    }

    &--red-bg {
      display: inline-block;
      position: relative;
      color: $color-white;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -15px;
        bottom: -3px;
        left: -15px;
        background-color: $color-red;
        z-index: -1;

        @include media-breakpoint-up(md) {
          top: 1px;
          bottom: -10px;
        }
      }

      span {
        position: relative;
        z-index: 1;
      }
    }

    &--gold-bg {
      display: inline-block;
      position: relative;
      color: $color-white;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -15px;
        bottom: -3px;
        left: -15px;
        background-color: $color-gold;
        z-index: -1;

        @include media-breakpoint-up(md) {
          top: 2px;
          bottom: -8px;
        }
      }

      span {
        position: relative;
        z-index: 1;
      }
    }

    &--white-bg {
      display: inline-block;
      position: relative;
      color: $color-orange;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -15px;
        bottom: -3px;
        left: -15px;
        background-color: $color-white;
        z-index: -1;

        @include media-breakpoint-up(md) {
          top: 2px;
          bottom: -8px;
        }
      }

      span {
        position: relative;
        z-index: 1;
      }
    }
  }

  &__subtitle {
    font-weight: $font-weight-normal;
    line-height: normal;
    font-size: 2rem;
    max-width: 1150px;
    margin: 0 10px 8px;

    @include media-breakpoint-up(lg) {
      margin: 0 auto 24px;
      font-size: 2.6rem;
    }

    br {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &--margin {
      margin: 0 10px 38px;

      @include media-breakpoint-up(lg) {
        margin: 0 auto 60px;
      }

      br {
        display: block;
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    a {
      color: $color-dark-grey;
      text-decoration: underline;

      &:hover {
        color: $color-green;
      }
    }

    &--main {
      margin: 15px 10px 8px;

      @include media-breakpoint-up(md) {
        max-width: 1150px;
        margin: 15px auto 8px;
      }

      br {
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }

    &--three {
      margin: 15px 10px 8px;
      max-width: unset;

      @include media-breakpoint-up(lg) {
        font-size: 2.8rem;
        line-height: 34px;
      }

      br {
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }

    &--gold {
      color: $color-gold;
    }
  }
}

.container {
  position: relative;
  z-index: 20;
  padding-right: 18px;
  padding-left: 18px;
  @include media-breakpoint-up(sm) {
    max-width: map-get($container-max-widths, sm);
  }
  @include media-breakpoint-up(md) {
    max-width: map-get($container-max-widths, md);
    padding-right: 15px;
    padding-left: 15px;
  }
  @include media-breakpoint-up(lg) {
    max-width: map-get($container-max-widths, lg);
  }
  @include media-breakpoint-up(xl) {
    max-width: map-get($container-max-widths, xl);
  }
}

.close:focus {
  outline: none;
}

.button {
  border-radius: 4px;
  background-color: $color-green;
  color: $color-white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: $font-weight-bold;
  line-height: normal;
  text-transform: uppercase;
  width: 100%;
  padding: 16px 0;
  margin: auto;
  @include trans(background-color, color, box-shadow, border-color);

  &:hover {
    text-decoration: none;
    background-color: $color-dark-green;
    color: $color-white;
    cursor: pointer;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }

  &--outline-green {
    box-shadow: none;
    background-color: transparent;
    border: 2px solid $color-green;
    color: $color-green;

    &:hover, &:focus{
      background-color: transparent;
      border-color: $color-dark-green;
      color: $color-dark-green;
      box-shadow: inset 0 0 0 1px $color-dark-green;
    }
  }

  &--outline-black {
    background-color: transparent;
    border: 2px solid $color-black;
    color: $color-black;
    box-shadow: inset 0 0 0 1px transparent;

    &:hover, &:focus{
      background-color: #e1e1e1;
      border-color: $color-black;
      color: $color-black;
      box-shadow: inset 0 0 0 1px $color-black;
    }
  }

  &--rounded {
    border-radius: 24px;
  }

  &--rounded-xl {
    border-radius: 32px;
  }

  &--sand-gradient {
    border-radius: 24px;
    color: $color-white;
    background-image: linear-gradient(270deg, rgba(125,95,21,1) 0%, rgba(178,140,46,1) 40%, rgba(225,203,94,1) 100%);
    background-size: 300% 100%;
    transition: all .4s ease-in-out;

    &:hover {
      background-position: 100% 0;
      transition: all .4s ease-in-out;
    }
  }

  &--red {
    background-color: $color-red;
    color: $color-white;

    &:hover {
      background-color: $color-dark-red;
      color: $color-white;
    }
  }
}

.hidden {
  display: none;
}

#scroll-up {
  display: none;

  @include media-breakpoint-up(lg) {
    background-color: $color-white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    position: fixed;
    bottom: 20px;
    right: 20px;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    opacity: 0;
    visibility: hidden;
    z-index: 50;
    @include trans(background-color, border-color, opacity, visibility);

    svg {
      fill: transparent;
      width: 30px;
      height: 30px;

      path {
        @include trans(stroke);
      }
    }

    &:hover {
      svg {
        animation: scrollUp .5s forwards;

        path {
          stroke: darken($color-gold, 15%);
        }
      }
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  @include media-breakpoint-up(xl) {
    width: 75px;
    height: 75px;

    svg {
      width: 50px;
      height: 50px;
    }
  }

  @media screen and (min-width: 1600px) {
    bottom: 90px;
    right: 135px;

    svg {
      width: 60px;
      height: 60px;
    }
  }
}

#qr-fixed {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
    position: fixed;
    left: 20px;
    max-width: 100px;
    z-index: 150;
  }

  @media screen and (min-width: 1760px) {
    left: 88px;
    bottom: 60px;
    max-width: 130px;
  }
}

.cookies {
  background-color: $color-white;
  position: fixed;
  bottom: 0;
  width: 100vw;
  border-top: 1px solid $color-red;
  z-index: $zindex-fixed;
  text-align: justify;
  font-size: 1rem;

  &__content {
    padding: 5px 15px;

    @include media-breakpoint-up(lg) {
      padding: 5px 30px;
    }
  }

  &__close {
    border: none;
    margin: 0;
    width: 100px;
    font-size: inherit;
    font-weight: 700;
    padding: 10px 20px;
  }

  p {
    margin: 0;
  }

  a {
    color: $color-dark-yellow;
    @include trans(color);

    &:hover{
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.4rem;
  }
}

@media (min-width: 1342px){
  .navbar-expand-custom {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-custom .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-custom .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-custom .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-custom > .container,
  .navbar-expand-custom > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-custom .navbar-collapse {
    display: -ms-flexbox;
    display: flex;
    flex-basis: auto;
  }
  .navbar-expand-custom .navbar-toggler {
    display: none;
  }}