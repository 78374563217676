/**
 * Hamburger do mobilnego menu
 */

$hamburger-padding-x: 15px !default;
$hamburger-padding-y: 15px !default;
$hamburger-layer-width: 37px !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-spacing: 11px !default;
$hamburger-layer-color: $color-red !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;
  @include trans(opacity, filter);

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  font-size: 0;

  &:hover {
    opacity: $hamburger-hover-opacity;
  }

  &:focus {
    outline: none;
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: calc($hamburger-layer-height / -2);

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    @include trans(transform 0.15s ease);
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &--black {
    &,
    &::before,
    &::after {
      background-color: $color-black;
    }
  }
}

/*
 * Spin
 */
.hamburger--spin {
  .hamburger-inner {
    @include trans(transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color);

    &::before {
      @include trans(top 0.1s 0.25s ease-in, opacity 0.1s ease-in, background-color);
    }

    &::after {
      @include trans(bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color);
    }
  }

  &:not(.collapsed) {
    .hamburger-inner {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        @include trans(top 0.1s ease-out, opacity 0.1s 0.12s ease-out);
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        @include trans(bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1));
      }
    }
  }
}
