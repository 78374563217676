.start-section {
  position: relative;
  padding-top: $navbar-height;
  padding-bottom: 0;
  background-size: 809px;
  background-position: center top;
  background-repeat: no-repeat;
  background-image: url("../assets/hero/hero-mobile.jpg");
  height: 686px;
  overflow: visible;

  @media only screen and (-o-min-device-pixel-ratio: 5/4),
  only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-device-pixel-ratio: 1.25),
  only screen and (min-resolution: 1.25dppx),
  (min-resolution: 72dpi) {
    background-image: url("../assets/hero/hero-mobile@2x.jpg");
  }

  @media only screen and (-o-min-device-pixel-ratio: 9/4),
  only screen and (-webkit-min-device-pixel-ratio: 2.25),
  only screen and (min-device-pixel-ratio: 2.25),
  only screen and (min-resolution: 2.25dppx),
  (min-resolution: 300dpi) {
    background-image: url("../assets/hero/hero-mobile@3x.jpg");
  }

  @include media-breakpoint-up(md) {
    height: 800px;
    background-size: 995px;
  }

  @include media-breakpoint-up(lg) {
    height: clamp(700px, 69vw, 700px);
    background-size: clamp(1350px, 133vw, 1340px);
    background-image: url("../assets/hero/hero-desktop.jpg");

    @media only screen and (-o-min-device-pixel-ratio: 5/4),
    only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 1.25dppx),
    (min-resolution: 72dpi) {
      background-image: url("../assets/hero/hero-desktop@2x.jpg");
    }

    @media only screen and (-o-min-device-pixel-ratio: 9/4),
    only screen and (-webkit-min-device-pixel-ratio: 2.25),
    only screen and (min-device-pixel-ratio: 2.25),
    only screen and (min-resolution: 2.25dppx),
    (min-resolution: 300dpi) {
      background-image: url("../assets/hero/hero-desktop@3x.jpg");
    }
  }

  @include media-breakpoint-up(xl) {
    height: max(700px, min(51.8vw, 997px));
    background-size: clamp(1350px, 100vw, 1920px);
    padding-top: 122px;
  }

  .container {
    height: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 36px 0 32px;
    text-align: left;
    height: 100%;

    @include media-breakpoint-up(md) {
      padding: 36px 0 10px;
    }

    @include media-breakpoint-up(lg) {
      padding: 65px 0 55px;
    }

    @include media-breakpoint-up(xl) {
      padding: 50px 0 55px 20px;
      padding-top: 4vw;
    }

    @media (min-width: 1850px) {
      padding-top: 139px;
    }

    &__title {
      color: $color-white;
      text-transform: uppercase;
      font-size: 2.5rem;
      font-weight: $font-weight-bold;

      @include media-breakpoint-up(md) {
        font-size: 3.5rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: clamp(3.5rem, 3.7vw, 5.53rem);
      }

      &--red {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 1px;
          right: -5px;
          bottom: 1px;
          left: -1px;
          background-color: $color-red;
          z-index: -1;
        }
      }
    }

    &__wrapper {
      background: url("../assets/static/hero-text-bg.svg") no-repeat center center/contain;
      max-width: 324px;
      width: 100%;

      @include media-breakpoint-up(md) {
        max-width: 440px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 677px;
      }
    }

    &__text-wrapper {
      color: $color-white;
      font-weight: $font-weight-bold;
      font-style: italic;
      margin-left: 104px;
      padding: 13px 0;

      @include media-breakpoint-up(md) {
        padding: 24px 0;
        margin-left: 145px;
      }

      @include media-breakpoint-up(xl) {
        margin-left: 212px;
        padding: 33px 0;
      }
    }

    &__text {
      font-size: 1.3rem;
      letter-spacing: 0.3px;
      font-weight: $font-weight-bold;

      @include media-breakpoint-up(md) {
        font-size: 1.7rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 2.9rem;
        line-height: 33px;
      }

      &--big {
        font-size: 1.9rem;
        letter-spacing: unset;

        @include media-breakpoint-up(md) {
          font-size: 2.3rem;
          margin-bottom: 5px;
        }

        @include media-breakpoint-up(xl) {
          font-size: 3.94rem;
          margin-bottom: 8px;
        }
      }

      &--small {
        @include media-breakpoint-up(xl) {
          font-size: 2.75rem;
          margin-bottom: 8px;
        }
      }
    }
  }

  &--main {
    background: linear-gradient(0deg, rgba(56, 38, 24, 1) 0%, rgba(131, 79, 40, 1) 30%, rgba(167, 109, 46, 1) 60%, rgba(213, 159, 62, 1) 100%);
    height: 595px;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      height: 700px;
    }

    @include media-breakpoint-up(md) {
      height: 830px;
    }

    @include media-breakpoint-up(lg) {
      height: 635px;
    }

    @include media-breakpoint-up(xl) {
      height: 850px;
    }

    @media (min-width: 1850px) {
      height: 997px;
    }

    .content {
      padding: 36px 0 0;
      text-align: center;

      @include media-breakpoint-up(md) {
        padding: 36px 0 0;
      }

      @include media-breakpoint-up(lg) {
        padding: 65px 0 0;
      }

      @include media-breakpoint-up(xl) {
        padding: 50px 0 55px 0;
      }

      &__title {
        color: $color-white;
        text-transform: uppercase;
        font-size: 5.8rem;
        font-weight: $font-weight-bold;
        line-height: 56px;

        .section__title--red-bg {
          &::after {
            top: -3px;
            bottom: -10px;
            left: -10px;
            right: -10px;

            @media (min-width: 1850px) {
              left: -18px;
              right: -18px;
              bottom: -20px;
            }
          }

          &:last-child {
            @media (min-width: 1850px) {
              letter-spacing: -6px;
            }
          }
        }

        @include media-breakpoint-up(md) {
          font-size: 9rem;
          line-height: 73px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 7rem;
          line-height: 71px;
          letter-spacing: -1px;
          position: absolute;
          left: -20px;
          bottom: 80px;
        }

        @include media-breakpoint-up(xl) {
          font-size: 9.8rem;
          left: 20px;
          line-height: 96px;
        }

        @media (min-width: 1850px) {
          font-size: 13.8rem;
          left: -153px;
          bottom: 148px;
          line-height: 134px;
        }
      }

      &__image-wrapper {
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-56%);

        @include media-breakpoint-up(lg) {
          transform: translateX(-40%);
        }

        @media (min-width: 1850px) {
          transform: translateX(-37.5%);
        }

        img {
          max-width: unset;
          width: 490px;

          @include media-breakpoint-up(sm) {
            width: 670px;
          }

          @include media-breakpoint-up(md) {
            width: 766px;
          }

          @include media-breakpoint-up(lg) {
            width: 885px;
          }

          @include media-breakpoint-up(xl) {
            width: 1140px;
          }

          @media (min-width: 1850px) {
            width: 1440px;
          }
        }
      }
    }
  }

  &--three {
    background: $color-white;
    height: unset;
    overflow: hidden;

    .swiper {
      height: 100%;
      max-width: 2110px;
      margin: auto;
    }

    .swiper-slide {
      background-color: $color-white;
    }

    .slide-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;
        align-items: flex-start;
      }

      &__bg-container {
        height: 420px;
        width: 100%;
        background-size: 860px;
        background-position: center top -70px;
        background-repeat: no-repeat;
        background-image: url("../assets/hero/hero-slider-1-mobile.jpg");

        @media only screen and (-o-min-device-pixel-ratio: 5/4),
        only screen and (-webkit-min-device-pixel-ratio: 1.25),
        only screen and (min-device-pixel-ratio: 1.25),
        only screen and (min-resolution: 1.25dppx),
        (min-resolution: 72dpi) {
          background-image: url("../assets/hero/hero-slider-1-mobile@2x.jpg");
        }

        @media only screen and (-o-min-device-pixel-ratio: 9/4),
        only screen and (-webkit-min-device-pixel-ratio: 2.25),
        only screen and (min-device-pixel-ratio: 2.25),
        only screen and (min-resolution: 2.25dppx),
        (min-resolution: 300dpi) {
          background-image: url("../assets/hero/hero-slider-1-mobile@3x.jpg");
        }

        @include media-breakpoint-up(sm) {
          height: 500px;
          background-size: 966px;
        }

        @include media-breakpoint-up(md) {
          height: 600px;
          background-size: 1150px;
        }

        @include media-breakpoint-up(lg) {
          background-image: url("../assets/hero/hero-slider-1.jpg");
          background-size: 928px;
          background-position: right -40px top -70px;
          height: 650px;
          max-height: 851px;

          @media only screen and (-o-min-device-pixel-ratio: 5/4),
          only screen and (-webkit-min-device-pixel-ratio: 1.25),
          only screen and (min-device-pixel-ratio: 1.25),
          only screen and (min-resolution: 1.25dppx),
          (min-resolution: 72dpi) {
            background-image: url("../assets/hero/hero-slider-1@2x.jpg");
          }

          @media only screen and (-o-min-device-pixel-ratio: 9/4),
          only screen and (-webkit-min-device-pixel-ratio: 2.25),
          only screen and (min-device-pixel-ratio: 2.25),
          only screen and (min-resolution: 2.25dppx),
          (min-resolution: 300dpi) {
            background-image: url("../assets/hero/hero-slider-1@3x.jpg");
          }
        }

        @include media-breakpoint-up(xl) {
          background-size: clamp(960px, 123vh, 1201px);
          background-position: right 0 top -122px;
          max-height: 850px;
          height: calc(100vh - 121px);
        }

        &--sobieski {
          background-image: url("../assets/hero/hero-slider-2-mobile.jpg");

          @media only screen and (-o-min-device-pixel-ratio: 5/4),
          only screen and (-webkit-min-device-pixel-ratio: 1.25),
          only screen and (min-device-pixel-ratio: 1.25),
          only screen and (min-resolution: 1.25dppx),
          (min-resolution: 72dpi) {
            background-image: url("../assets/hero/hero-slider-2-mobile@2x.jpg");
          }

          @media only screen and (-o-min-device-pixel-ratio: 9/4),
          only screen and (-webkit-min-device-pixel-ratio: 2.25),
          only screen and (min-device-pixel-ratio: 2.25),
          only screen and (min-resolution: 2.25dppx),
          (min-resolution: 300dpi) {
            background-image: url("../assets/hero/hero-slider-2-mobile@3x.jpg");
          }

          @include media-breakpoint-up(lg) {
            background-image: url("../assets/hero/hero-slider-2.jpg");
            background-size: 928px;
            background-position: right -40px top -70px;
            height: 650px;
            max-height: 851px;

            @media only screen and (-o-min-device-pixel-ratio: 5/4),
            only screen and (-webkit-min-device-pixel-ratio: 1.25),
            only screen and (min-device-pixel-ratio: 1.25),
            only screen and (min-resolution: 1.25dppx),
            (min-resolution: 72dpi) {
              background-image: url("../assets/hero/hero-slider-2@2x.jpg");
            }

            @media only screen and (-o-min-device-pixel-ratio: 9/4),
            only screen and (-webkit-min-device-pixel-ratio: 2.25),
            only screen and (min-device-pixel-ratio: 2.25),
            only screen and (min-resolution: 2.25dppx),
            (min-resolution: 300dpi) {
              background-image: url("../assets/hero/hero-slider-2@3x.jpg");
            }
          }

          @include media-breakpoint-up(xl) {
            background-size: clamp(960px, 123vh, 1201px);
            background-position: right 0 top -122px;
            max-height: 850px;
            height: calc(100vh - 121px);
          }
        }
      }

      &__wrapper {
        position: relative;
        padding: 0 20px 30px;
        background-color: $color-white;

        @include media-breakpoint-up(lg) {
          height: 100%;
          isolation: isolate;
          flex-grow: 2;
          width: 100%;
          max-width: 450px;
          padding: 50px 0 30px 50px;
          text-align: left;
        }

        @include media-breakpoint-up(xl) {
          max-width: 910px;
          padding: 80px 0 30px 90px;
        }
      }

      &__text {
        font-size: 1.6rem;
        line-height: 120%;
        margin-bottom: 16px;

        @include media-breakpoint-up(sm) {
          font-size: 1.8rem;
        }

        @include media-breakpoint-up(md) {
          font-size: 2rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 3.6rem;
          margin-bottom: 48px;
        }
      }

      &__image-wrapper {
        max-width: 290px;
        margin: auto;
        transform: translateX(-10px);

        @include media-breakpoint-up(sm) {
          max-width: 370px;
        }

        @include media-breakpoint-up(md) {
          max-width: 450px;
        }

        @include media-breakpoint-up(lg) {
          margin: 0;
          max-width: 350px;
        }

        @include media-breakpoint-up(xl) {
          max-width: 825px;
        }
      }

      &__line {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
          position: absolute;
          right: -80px;
          top: 0;
          bottom: 0;
          height: 100%;
          z-index: -1;
        }

        @include media-breakpoint-up(xl) {
          right: -145px;
          top: -171px;
          bottom: 48px;
          height: 125%;

          svg {
            width: 199px;
            height: 1246px;
          }
        }
      }
    }
  }
}
