.map-section {
  background-color: #F3F8E8;
  padding-bottom: 80px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 90px;
  }

  .section__title {
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 15px;
    }
  }

  &__subtitle {
    font-size: 1.6rem;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      font-size: 2.6rem;
      margin-bottom: 30px;
    }
  }

  .map-wrapper {
    position: relative;
  }

  .map-overlay {
    position: absolute;
    inset: 0;
    z-index: 2;
    cursor: pointer;

    &.hidden {
      display: none;
    }
  }

  iframe {
    height: 385px;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: 550px;
    }

    @include media-breakpoint-up(lg) {
      height: 800px;
    }
  }

  .button {
    margin-top: 20px;
    max-width: 304px;
    z-index: 1;
    position: relative;

    @include media-breakpoint-up(lg) {
      margin-top: 30px;
    }
  }

  &--main {
    @include media-breakpoint-up(lg) {
      padding-top: 75px;
    }

    .map-wrapper {
      margin-top: 40px;

      @include media-breakpoint-up(lg) {
        margin-top: 60px;
      }
    }
  }

  &--three {
    background-color: $color-light-grey;

    @include media-breakpoint-up(lg) {
      padding-top: 75px;
    }

    .map-wrapper {
      margin-top: 40px;

      @include media-breakpoint-up(lg) {
        margin-top: 60px;
      }
    }

    iframe {
      @include media-breakpoint-up(lg) {
        height: 625px;
      }
    }

    .button {
      max-width: 181px;
      padding: 14px 0;

      @include media-breakpoint-up(lg) {
        margin-top: 50px;
      }
    }
  }
}