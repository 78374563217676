@keyframes scrollUp {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 0;
    transform: translateY(-5px);
  }
  51% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fly {
  0%{
    transform: translateY(0px);
   }

  33% {
    transform: translateY(20px)
  }

  66% {
    transform: translateY(-20px)
  }

  100%{
    transform: translateY(0px);
  }
}

@keyframes fly2 {
  0%{
    transform: translateY(0px) translateX(0)
  }

  33% {
    transform: translateY(20px) translateX(-5px) rotate(15deg);
  }

  50% {
    transform: translateX(0) rotate(0) ;
  }

  66% {
    transform: translateY(-20px) translateX(5px) rotate(-15deg);
  }

  100%{
    transform: translateY(0px) translateX(0);
  }
}

@keyframes flyAround {
  0% {
    transform: translate(-330px, 50px) scale(0.9);
  }

  50% {
    transform: translate(150px, -50px) scale(1.1) rotate(360deg);
  }

  50.00001% {
    transform: translate(150px, -50px) scale(1.1);
  }

  100% {
    transform: translate(-330px, 50px) scale(0.9) rotate(360deg);
  }
}

@keyframes flyAroundMobile {
  0% {
    transform: translate(-150px, 50px) scale(0.9);
  }

  50% {
    transform: translate(150px, -50px) scale(1.1) rotate(360deg);
  }

  50.00001% {
    transform: translate(150px, -50px) scale(1.1);
  }

  100% {
    transform: translate(-150px, 50px) scale(0.9) rotate(360deg);
  }
}

@keyframes zindex {
  0% {
    z-index: 1;
  }

  50% {
    z-index: 1;
  }

  50.00001% {
    z-index: 3;
  }

  100% {
    z-index: 3;
  }
}

@keyframes rotate {
  0%, 100% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(360deg);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.anim-bubble {
  transform-origin: 50% 50%;
  animation: bubbles 1.5s linear 0s infinite normal;

  &--delay {
    animation-delay: .5s;
  }

  &--long {
    animation-duration: 3s;
  }
}

.anim-rotate {
  transform-box: fill-box;
  transform-origin: 50% 50%;
  animation: rotate 10s linear 0s infinite alternate;

  &--delay {
    animation-delay: .5s;
  }
}