.contact-section {
  text-align: center;
  padding: 64px 0;

  @include media-breakpoint-up(xl) {
    padding: 76px 0;
  }

  .logo {
    max-width: 246px;
    margin: 0 auto 28px;

    @include media-breakpoint-up(lg) {
      margin: 0 0 46px;
    }

    @include media-breakpoint-up(xl) {
      width: 100%;
      max-width: 310px;
    }
  }

  .content {
    text-align: center;

    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 1.7fr 2fr 1fr;
      text-align: left;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 561px 328px;
    }

    &__title {
      font-size: 2.4rem;
      font-weight: $font-weight-bold;
      margin-bottom: 28px;
      text-transform: uppercase;
      line-height: normal;

      @include media-breakpoint-up(xl) {
        font-size: 3.6rem;
        margin-bottom: 25px;
      }
    }

    &__text {
      font-size: 1.6rem;
      line-height: normal;
      margin-bottom: 12px;

      @include media-breakpoint-up(xl) {
        margin-bottom: 16px;
      }

      &--big {
        font-size: 1.8rem;
        margin-bottom: 8px;

        @include media-breakpoint-up(lg) {
          font-size: 1.6rem;
        }
      }

      &--small {
        font-size: 1.2rem;
        margin-bottom: 28px;

        @include media-breakpoint-up(lg) {
          font-size: 1.4rem;
        }
      }
    }

    .organizer {
      @include media-breakpoint-up(lg) {
        margin-top: -5px;
      }

      &__wrapper {
        @include media-breakpoint-up(lg) {
          display: flex;
          gap: 35px;
        }

        @include media-breakpoint-up(xl) {
          gap: 106px;
        }
      }

      &__links {
        margin-bottom: 28px;
      }

      &__link {
        font-size: 1.6rem;
        line-height: normal;

        &--big {
          display: block;
          font-size: 1.8rem;
          font-weight: $font-weight-bold;
          margin-bottom: 12px;
        }

        &--phone {
          @include media-breakpoint-up(md) {
            pointer-events: none;
          }
        }
      }
    }

    &__links {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      @include media-breakpoint-up(lg) {
        align-items: flex-start;
        gap: 14px;
        margin-top: 30px;
      }

      a {
        font-size: 1.6rem;
        text-decoration: underline !important;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          font-size: 1.4rem;
        }
      }
    }
  }

  a {
    color: $color-dark-grey;
    @include trans(color);

    &:hover {
      text-decoration: none;
      color: $color-gold;
    }
  }

  &--main {
    .content {
      &__title {
        @include media-breakpoint-up(lg) {
          margin-left: 15px;
          margin-bottom: 40px;
        }

        .section__title--red-bg {
          &::after {
            @include media-breakpoint-up(lg) {
              top: -7px;
              bottom: -12px;
            }
          }
        }
      }

      &__links {
        @include media-breakpoint-up(lg) {
          margin-top: 45px;
        }
      }
    }
  }
}