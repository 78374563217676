@import "~bootstrap/scss/bootstrap";
@import "aos/dist/aos.css";
@import "/node_modules/swiper/swiper-bundle.css";

@import "components/variables";
@import "components/mixins";
@import "components/base";
@import "components/components";
@import "components/animations";

//partials
@import "partials/header";
@import "partials/start-section";
@import "partials/rules-section";
@import "partials/awards-section";
@import "partials/challenge-section";
@import "partials/download-section";
@import "partials/schedule-section";
@import "partials/winners-section";
@import "partials/faq-section";
@import "partials/map-section";
@import "partials/info-section";
@import "partials/footer";