/**
 * Funkcje wykorzystywane w pozostałych plikach
 */

@function unfoldTransition($transition, $prefix: null) {
  // Default values
  $property: all;
  $duration: $transition-default-duration;
  $easing: $transition-default-easing;
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i);
      @if ($prefix and $p == 'transform') {
        $p: #{$prefix}#{$p};
      }
    } @else {
      $p: nth($defaultProperties, $i)
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}

// Usage:   @include transition(width, height 0.3s ease-in-out);
// Pass in any number of transitions
@mixin trans($transitions...) {
  $webkitUnfoldedTransitions: ();
  $unfoldedTransitions: ();
  $willChange: ();
  @each $transition in $transitions {
    $webkitUnfoldedTransitions: append($webkitUnfoldedTransitions, unfoldTransition($transition, '-webkit-'), comma);
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
    $willChange: append($willChange, nth($transition, 1), comma);
  }

  transition: $unfoldedTransitions;
  //will-change: $willChange; // wg https://developer.mozilla.org/en-US/docs/Web/CSS/will-change tą właściwość najlepiej dodawać przez JS w momencie faktycznej animacji
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer & FF
  & {
    scrollbar-width: thin;
    scrollbar-color: $foreground-color $background-color;
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin media-breakpoint-up($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($grid-breakpoints)}.";
  }
}

@mixin media-breakpoint-down($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {

    @media (max-width: map-get($grid-breakpoints, $breakpoint) - 1px) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($grid-breakpoints)}.";
  }
}
