.schedule-section {
  padding-bottom: 80px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
  }

  .section__title {
    @include media-breakpoint-up(md) {
      margin-bottom: 48px;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #bdd67a;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $color-dark-green;
    @include trans(background-color);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: $color-green;
  }
  .draws {
    padding: 0 8px;

    @include media-breakpoint-up(lg) {
      padding: 0 14px;
    }

    &__wrapper {
      @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }

      @include media-breakpoint-up(lg) {
        gap: 35px;
      }
    }
  }

  .draw {
    text-align: center;
    border-radius: 8px 8px 120px 8px;
    border: 4px solid $color-light-green;
    background-color: $color-light-green;
    padding: 36px 35px;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 36px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 28px 35px 32px;
    }

    &--main {
      padding: 42px 22px;

      @include media-breakpoint-up(md) {
        margin-bottom: 35px !important;
      }

      @include media-breakpoint-up(lg) {
        padding: 43px 35px 66px;
      }
    }

    &__title {
      color: $color-white;
      font-size: 2.4rem;
      font-weight: $font-weight-bold;
      line-height: 120%;
      text-transform: uppercase;
      margin-bottom: 18px;

      @include media-breakpoint-up(sm) {
        br {
          display: none;
        }
      }
      @include media-breakpoint-up(md) {
        margin-bottom: 22px;

        br {
          display: block;
        }
      }
      @include media-breakpoint-up(lg) {
        br {
          display: none;
        }
      }

      &--big {
        font-size: 3rem;
        margin-bottom: 22px;

        @include media-breakpoint-up(sm) {
          br {
            display: none;
          }
        }

        @include media-breakpoint-up(lg) {
          font-size: 3.6rem;
          margin-bottom: 24px;
        }
      }
    }

    &__date {
      background-color: $color-white;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      width: 100%;
      font-size: 2rem;
      font-weight: $font-weight-bold;
      line-height: normal;
      margin-bottom: 12px;

      &--big {
        height: 55px;
        font-size: 3.2rem;
        max-width: 350px;
        margin: 0 auto 28px;

        @include media-breakpoint-up(lg) {
          height: 94px;
          max-width: 447px;
          font-size: 4rem;
          margin: 0 auto;
        }
      }
    }

    &__list-wrapper {
      max-height: 268px;
      max-width: 205px;
      margin: 0 auto;
      overflow-y: auto;
      z-index: 10;
      position: relative;
    }

    &__list {
      position: relative;
      padding-right: 10px;
    }

    &__decor-1 {
      max-width: 127px;
      margin: 0 auto;

      svg {
        transform: scaleX(-1);
      }

      @include media-breakpoint-up(lg) {
        position: absolute;
        left: 32px;
        top: 52px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 182px;
        left: 123px;
        top: 43px;
      }
    }

    &__decor-2 {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        max-width: 127px;
        position: absolute;
        right: 49px;
        top: 48px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 182px;
        right: 123px;
        top: 43px;
      }
    }
  }
}